import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import CustomImage from "../../components/custom-image/custom-image.component"

export const Wrapper = styled(SectionWrapper)`
  padding-top: 4rem;

  ${props =>
    props.scrollSnap
      ? `
      padding-top: 0;
      padding-bottom: 0;
      scroll-snap-align: start;
      scroll-snap-stop: always;
      
      & > div{
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }`
      : ""}

  ${({ theme }) => theme.breakpoints.down("xs")} {
    ${props =>
      props.scrollSnap
        ? `
        padding-top: 5rem;
        padding-bottom: 5rem;
        
        & > div {
          height: unset;
        }`
        : ""}
  }

  h3 {
    text-align: center;
    font-weight: 400;
    font-size: clamp(2.5rem, 7vw, 3rem);
    margin: 0 auto 0.25rem;
    line-height: 1.3em;

    ${({ theme }) => theme.breakpoints.down("xs")} {
      font-size: 2.5rem;
      line-height: 1em;
      margin-bottom: 30px;
    }
  }

  .items {
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: space-between;
    // align-items: flex-start;

    .item {
      flex-basis: 27%;
      text-align: center;
      margin-top: 2em;
      padding: 0 1.5rem;

      .title,
      .description {
        text-align: center;
      }
      .description p:last-child {
        margin-bottom: 0;
      }

      .title {
        font-size: ${({ theme }) => theme.typography.pxToRem(36)};
        font-family: ${({ theme }) => theme.fonts.secondary};
      }

      .description p,
      .description li {
        font-family: ${({ theme }) => theme.fonts.tertiary};
        font-size: ${({ theme }) => theme.typography.pxToRem(20)};
      }

      .gatsby-image-wrapper {
        max-width: 80%;
        margin: 0 auto;
      }

      ${({ theme }) => theme.breakpoints.down("xs")} {
        flex-basis: 100%;
        margin-top: 2em;
      }
    }
  }

  .slick-slider {
    padding-bottom: 40px;

    .slick-prev,
    .slick-next {
      top: unset;
      bottom: 0;
      width: 100px;
      height: 30px;
      background: url("/img/arrow-straight.svg") 0 0 no-repeat;
      background-size: contain;
      transform: unset;

      &::before {
        display: none;
      }
    }

    .slick-prev {
      left: 0;
    }

    .slick-next {
      right: 0;
      transform: scale(-1, 1);
    }
  }
`

export const Subtitle = styled.p`
  text-align: center;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.primary};
  margin-top: 0;
  font-size: clamp(1.1rem, 1.5vw, 1.25rem);

  ${({ theme }) => theme.breakpoints.down("xs")} {
    font-size: 1.15rem;
  }
`

export const Icon = styled(CustomImage)`
  [data-placeholder-image] {
    background-color: transparent !important;
  }
`
